<template>
  <div>
    <b-button variant="primary" @click="isModalShow=true">
      <b-spinner v-if="isLoading" small class="mr-50" />
      <feather-icon v-else
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Add Milestone</span>
    </b-button>
    <b-modal
      v-model="isModalShow"
      title="Create new milestone"
      hide-footer
      size="lg" modal-class="modal-primary"
    >
      <validation-observer
        ref="createForm"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <validation-provider
            name="Name"
            v-slot="validationContext"
            :rules="{ required: true}"
          >
            <b-form-group
              label="Name"
              label-for="h-category-name"
            >
              <b-form-input
                v-model="name"
                id="category-name"
                name="category-name"
                type="text"
                :state="getValidationState(validationContext)"
                aria-describedby="input-category-name-feedback"
              />
              <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            name="Key"
            v-slot="validationContext"
            :rules="{ required: true}"
          >
            <b-form-group
              label="Key"
              label-for="h-category-key"
            >
              <b-form-input
                v-model="key"
                id="category-key"
                name="category-key"
                type="text"
                :state="getValidationState(validationContext)"
                aria-describedby="input-category-key-feedback"
              />
              <b-form-invalid-feedback id="input-category-key-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
        
        <div class="d-flex justify-content-between my-2">
          <b-button variant="danger" @click="cancel">Cancel</b-button>
          <b-button type="submit" variant="primary" @click="create">Create</b-button>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import service from '../../service'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
export default {
  components:{
    ValidationProvider,
    ValidationObserver
  },
  props:{
    display_order: {type: Number, default: null}
  },
  data() {
    return {
      isLoading: false,
      isModalShow: false,
      name: null,
      key: null,
    }
  },
  methods: {
    async create() {
      let validation = await this.$refs.createForm.validate();
      if (validation){
        this.isLoading = true;
        let newItem = await service.createMileStone({ name: this.name, key: this.key, display_order: this.display_order});
        this.isLoading = false;
        this.isModalShow = false;
        if (newItem){
          this.$store.dispatch("pushSuccessNotify", {
            text: "Success",
          });
          this.$emit("create")
        }
      }
    },
    cancel(){
      this.isModalShow = false;
      this.name = null;
      this.key = null;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
}
</script>
