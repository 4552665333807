<template>
  <b-card class="text-center">
    <b-link :to="{ name: 'breaking-lesson-detail', params: { lesson_id: lesson._id } }">
      <b-img class="w-100" :src="lesson.thumbnail.value" v-if="lesson.thumbnail.value" />
      <b-img v-else :blank="true" blank-color="#ccc" alt="placeholder" height="172" class="w-100" />
      <h2 class="my-2 text-truncate-2 h1">{{ lesson.title || lesson._id }} </h2>
      <small class="d-block mt-2" style="color: #bbb">
        Last edited: {{ lesson.updatedAt | local_time_string }}
      </small>
    </b-link>
  </b-card>
</template>

<script>
export default {
  props: {
    lesson: { type: Object, required: true },
  },
}
</script>
