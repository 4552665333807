<template>
  <div class="page-content">
    <page-breadcrumb title="Lesson" class="mb-3" />

    <category-card v-for="(category) in categories" :key="category._id"
      :category="category" class="mt-1"
    />
    <div class="d-flex justify-content-center pt-3">
      <category-create-form @create="getCategoryList" :display_order="display_order" />
    </div>
    
  </div>
</template>
<script>
import service from '../service'
import CategoryCard from './_components/CategoryCard.vue'
import CategoryCreateForm from './_components/CategoryCreateForm.vue'
export default {
  components: {
    CategoryCard,
    CategoryCreateForm
  },
  data() {
    return {
      categories: [],
      display_order: null
    }
  },
  created() {
    this.getCategoryList()
  },
  methods: {
    async getCategoryList() {
      let data = await service.getCategoryList()
      this.categories = data.list;
      this.display_order = data.total;
    },
  },
}
</script>
